.branded {
    font-family: $font-brand;
}

@include color-mode(light) {
    .logo {
        content: url("/img/hb.png")
    }
}

@include color-mode(dark) {
    .logo {
        content: url("/img/hb-dark.png")
    }
}

@include color-mode(light) {
    .hidden {
        color: var(--bs-body-bg);
    }
}

@include color-mode(dark) {
    .hidden {
        color: var(--bs-body-bg);
    }
}

a:link,
a:active,
a:visited,
h1 > a:link,
h1 > a:active,
h1 > a:visited {
    text-decoration-style: dotted;
}

h1,
h2,
h3,
h4,
h5 {
    a {
        text-decoration: none;
    }
}

a:link,
a:hover,
a:active,
a:visited {
    code {
        color: $color-code;
    }
}

header {
    h1 {
        font-size: 70px;
    }

    h3 {
        font-size: 18px;
    }
}

code {
    color: $color-code;
    background-color: $color-code-bg;
    border-color: $color-code-border;
    border-width: $code-border-width;
    border-radius: $code-border-radius;
    border-style: solid;
    padding: 2px;
    font-weight: bolder;
}

pre {
    code {
        border: none;
        font-weight: normal;
    }
}

.app-heading {
    font-size: 64px;
}

.app-icon-heading {
    width: 85px;
    height: 85px;
}

.app-icon-list {
    width: 150px;
    height: 150px;
}

@media (max-width: 414px) {
    .app-heading {
        font-size: 56px;
    }
}

@media (max-width: 375px) {
    header {
        h1 {
            font-size: 62px;
        }
    }

    .app-icon-list {
        width: 120px;
        height: 120px;
    }

    .app-heading {
        font-size: 48px;
    }

    .app-icon-heading {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 320px) {
    header {
        h1 {
            font-size: 55px;
        }
        h3 {
            font-size: 14px;
        }
    }

    .app-icon-list {
        width: 100px;
        height: 100px;
    }

    .app-heading {
        font-size: 40px;
    }

    .app-icon-heading {
        width: 60px;
        height: 60px;
    }
}
