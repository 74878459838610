//
// Customizing Bootstrap SASS
// https://getbootstrap.com/docs/5.3/customize/overview/
//

// 1. Include functions first (to manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$color-mode-type: media-query;
$primary: #E0161A;

// 3. Include remainder of required Bootstrap stylesheets
// (including any separate color mode stylesheets)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 5. Optionally include any other parts as needed

// Core
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/type";

// Layout & components
@import "../node_modules/bootstrap/scss/accordion";
// @import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/badge";
// @import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/buttons";
// @import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/carousel";
// @import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/list-group";
// @import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
// @import "../node_modules/bootstrap/scss/offcanvas";
// @import "../node_modules/bootstrap/scss/pagination";
// @import "../node_modules/bootstrap/scss/placeholders";
// @import "../node_modules/bootstrap/scss/popover";
// @import "../node_modules/bootstrap/scss/progress";
// @import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/tables";
// @import "../node_modules/bootstrap/scss/toasts";
// @import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/transitions";

// 6. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 7. Add additional custom code here
$font-brand: 'HillHouseMedium', Arial, sans-serif;

$color-code: $gray-700;
$color-code-bg: $gray-100;
$color-code-border: $gray-400;
$code-border-radius: 3px;
$code-border-width: 1px;

@import "custom";
